<template>
  <div id="user-management" class="col-12">
    <v-row no-gutters id="user-form">
      <v-col cols="12" class="main-content">
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="submit"
          autocomplete="off"
        >
          <v-row no-gutters>
            <v-col cols="12" md="4">
            </v-col>

            <v-col cols="12" md="4">
              <v-card class="box-height height-responsive" outlined tile>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <p class="label">Username</p>
                      <v-text-field
                        v-model="userName"
                        required
                        autocomplete="off"
                        class="username-input"
                        :rules="userNameRules"
                        placeholder="New Username"
                        filled
                        :validate-on-blur="true"
                        @input="clearNewUserInfo(); handleInputCurrentUsername();"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="pt-3">
                    <v-col cols="12" md="12">
                      <p class="label">User Group Type</p>
                      <v-select
                        v-model="userGroup"
                        :items="groups"
                        item-text="name"
                        item-value="id"
                        :rules="userGroupRules"
                        filled
                        placeholder="Select User Group Type"
                        append-icon="fas fa-caret-down"
                        :validate-on-blur="true"
                        class="custom-hide-input"
                        @click="clearNewUserInfo()"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="pt-2">
                    <v-col cols="12" md="12">
                      <div v-if="dataCreateUser != null" class="user-create-password">
                        <p>User successfully created.</p>
                        <p>Username: {{ dataCreateUser.username }}</p>
                        <p>User Role: {{ dataCreateUser.userRole }}</p>
                        <p>Password: {{ dataCreateUser.password }}</p>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters >
                    <v-col cols="12" md="12" style="display: flex; justify-content: center;">
                      <button type="submit" class="create-user-button">
                        Create User
                      </button>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss" src="../../assets/css/user-management.scss"></style>
<script>
import _ from "lodash";
import { footerProps } from "../../constants";
import { groupService, userService } from "../../services";
export default {
  components: {},
  data() {
    return {
      showConfirm: false,
      showPass: false,
      userName: "",
      userGroup: "",
      email: "",
      password: "",
      confirmPass: "",
      screen: "",
      editUsername: "",
      editUserGroup: "",
      editEmail: "",
      editScreenAccess: "",
      userInfo: null,
      search: "",
      newPassword: "",
      confirmNewPass: "",
      showNewPass: false,
      showConfirmNewPass: false,
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      messUsername: null,
      messPassword: null,
      total: 0,
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false,
          filterable: false,
        },
        { text: "User Name", value: "userName" },
        { text: "Email Address", value: "email" },
        { text: "User Group", value: "group" },
        { text: "Screen Access", value: "screen" },
        { text: "Registered", value: "created_at" },
        {
          text: "Actions",
          value: "actions",
          width: 150,
          sortable: false,
          filterable: false,
        },
      ],
      users: [],
      groups: [],
      screens: [
        {
          id: 1,
          name: "Screen 1"
        },
        {
          id: 2,
          name: "Screen 2"
        },
        {
          id: 3,
          name: "All screens"
        },
      ],
      pageSize: 4,
      currentPage: 1,
      valid: false,
      emailRules: [
        (v) => !!v || (!this.email ? true : "Email is required"),
        (v) => /.+@.+\..+/.test(v) || (!this.email ? true : "Email must be valid"),
      ],
      emailEditRules: [
        (v) => !!v || (!this.editEmail ? true : "Email is required"),
        (v) => /.+@.+\..+/.test(v) || (!this.editEmail ? true : "Email must be valid"),
      ],
      passwordRules: [
        (v) => {
          if(v){
            if (v.length < 8) return "Password must be at least 8 characters";
            if (!/(?=.*[A-Z])/.test(v)) return "Password must contain at least 1 uppercase letter";
            if (!/(?=.*[!@#$%^&+=*_-])/.test(v)) return "Password must contain at least 1 special character";
            if (!/(?=.*\d)/.test(v)) return "Password must contain at least 1 number";
            return true;
          }
          return true;
        }
      ],
      changePasswordRules: [
        (v) => {
          if (!v) return "Password is required";
          if (v.length < 8) return "Password must be at least 8 characters";
          if (!/(?=.*[A-Z])/.test(v)) return "Password must contain at least 1 uppercase letter";
          if (!/(?=.*[!@#$%^&+=*_-])/.test(v)) return "Password must contain at least 1 special character";
          if (!/(?=.*\d)/.test(v)) return "Password must contain at least 1 number";
          return true;
        }
      ],
      userGroupRules: [(v) => !!v || "User Group is required"],
      screenAccessRules: [(v) => !!v || "Screen Access is required"],
      dialog: false,
      footer: {},
      confirmDialog: false,
      userItem: { userName: "" },
      changePassDialog: false,
      passValid: false,
      dialogAction: () => null,
      dialogMessage: "",
      dataCreateUser:null,
      userNameTaken:false,
      timeoutCheckUsername: null,
    };
  },
  created() {
    this.footer = Object.assign({}, this.footer, footerProps);
    this.getListGroups().then(() => {
      this.getListUsers();
    });
  },
  mounted() {
    this.userName = null;
  },
  methods: {
    updatePagination(pagination) {
      this.currentPage = pagination.page;
      this.pageSize = pagination.itemsPerPage;
      this.$set(
        this.footer,
        "pageText",
        `${this.currentPage} of ${this.total}`
      );
    },
    getListGroups() {
      return groupService
        .getGroupList()
        .then((res) => {
          if (res?.data?.data?.length) {
            const data = res.data.data;
            this.groups = [...data];
            return res;
          }
        })
        .catch(() => {
          return null;
        });
    },
    getListUsers() {
      userService.getListUsers().then((res) => {
        if (res?.data?.data?.length) {
          const data = res.data.data;
          data.map((val) => {
            val.userName = val.username;
            val.group = this.getGroupName(val.user_group_id);
          });
          this.users = [...data];
          this.total = Math.ceil((res.data.data.length || 0) / this.pageSize);
        }
      });
    },
    getGroupName(groupId) {
      if (!this.groups?.length || !groupId) {
        return "";
      }
      const group = _.find(this.groups, (obj) => obj.id == groupId);
      return group?.name || "";
    },
    submit() {
      if (this.$refs.form.validate()) {
        const data = this.prepareData(
          this.userName,
          this.userGroup,
          this.email,
          this.password,
          this.screen,
        );
        userService
          .storeUser(data)
          .then((res) => {
            if (res.status !== 200) throw res;
            let data_user = res.data.data;
            this.getListUsers();
            this.apiMessage = "User has been successfully created";
            this.apiTitle = "Success";
            this.messUsername = "Username: " + data_user.username;
            this.messPassword = "Password: " + data_user.password;
            this.revert();
            this.messageDialog = true;
            this.dataCreateUser = res.data.data;
          })
          .catch((err) => {
            let message = "User cannot be created";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.handleMessageProfile();
            this.messageDialog = true;
          });
      }else{
        this.dataCreateUser = null;
        this.userNameTaken = false;
      }
    },
    revert() {
      this.$refs.form.reset();
    },
    prepareData(userName, userGroup, email, password, screen) {
      const data = {
        username: userName,
        user_group_id: userGroup,
      };
      if(email){
        data.email = email ? email : null;
      }
      if (password) {
        data.password = password;
      }
      if(screen){
        data.screen = screen !== 3 ? screen : null;
      }
      return data;
    },
    unlockUser(item) {
      if (item?.id) {
        userService
          .unlockUser(item.id)
          .then((res) => {
            if (res.status !== 200) throw res;
              this.userItem = Object.assign({}, this.userItem, { userName: "" });
              this.closeConfirmDialog();
              this.getListUsers();
              this.apiMessage = "User has been successfully unlocked";
              this.apiTitle = "Success";
              this.handleMessageProfile();
              this.messageDialog = true;
          })
          .catch((err) => {
            let message = "User cannot be unlocked";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
              this.apiMessage = message;
              this.apiTitle = "Error";
              this.handleMessageProfile();
              this.messageDialog = true;
          });
      }
    },
    openDialog(item) {
      if (item?.id) {
        userService
          .getUser(item.id)
          .then((res) => {
            if (res?.data?.data) {
              this.setUserInfo(res.data.data);
              this.dialog = true;
            }
          })
          .catch();
      }
    },
    closeDialog() {
      this.setUserInfo(null);
      this.dialog = false;
    },
    openConfirmDialog(item, action, message) {
      this.userItem = Object.assign({}, this.userItem, item);
      this.dialogAction = action;
      this.dialogMessage = message;
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.userItem = Object.assign({}, this.userItem, { userName: "" });
      this.confirmDialog = false;
      this.dialogAction = () => null;
      this.dialogMessage = "";
    },
    revertEdit() {
      this.setUserInfo(this.userInfo);
    },
    setUserInfo(data) {
      if (data) {
        this.editUserGroup = data.user_group_id || "";
        this.editUsername = data.username || "";
        this.editEmail = data.email || "";
        this.userInfo = data;
        this.editScreenAccess = data.screen ? data.screen : 3 || "";
      } else {
        this.editUserGroup = "";
        this.editUsername = "";
        this.editEmail = "";
        this.userInfo = null;
        this.editScreenAccess = "";
      }
    },
    submitEdit() {
      const data = this.prepareData(
        this.editUsername,
        this.editUserGroup,
        this.editEmail,
        null,
        this.editScreenAccess,
      );
      userService
        .updateUser(this.userInfo.id, data)
        .then((res) => {
          if (res.status !== 200) throw res;
          if(this.userInfo.id == this.$store.state.user.id){
            this.$store.commit('setUserScreen', this.editScreenAccess === 3 ? null : this.editScreenAccess);
          }
          this.apiMessage = "User's information has been successfully updated";
          this.apiTitle = "Success";
          this.handleMessageProfile();
          this.messageDialog = true;
          this.getListUsers();
          this.closeDialog();
        })
        .catch((err) => {
          let message = "User's information cannot be updated";
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          }
          this.apiMessage = message;
          this.apiTitle = "Error";
          this.handleMessageProfile();
          this.messageDialog = true;
        });
    },
    deleteUser(item) {
      if (item?.id) {
        userService
          .deleteUser(item.id)
          .then((res) => {
            if (res.status !== 200 && res.status !== 204) throw res;
            this.closeConfirmDialog();
            this.apiMessage = "User has been successfully deleted";
            this.apiTitle = "Success";
            this.handleMessageProfile();
            this.messageDialog = true;
            this.getListUsers();
          })
          .catch((err) => {
            let message = "User cannot be deleted";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.handleMessageProfile();
            this.messageDialog = true;
          });
      }
    },
    showNewPassDialog(item) {
      this.userItem = Object.assign({}, this.userItem, item);
      this.changePassDialog = true;
    },
    closePassDialog() {
      this.$refs.editPass.reset();
      this.userItem = Object.assign({}, this.userItem, { userName: "" });
      this.changePassDialog = false;
    },
    submitPass() {
      const data = {
        new_password: this.newPassword,
      };
      userService
        .resetPassForUser(this.userItem.id, data)
        .then((res) => {
          if (res.status !== 200) throw res;
          this.closePassDialog();
          this.apiMessage = "User's information has been successfully updated";
          this.apiTitle = "Success";
          this.messUsername = "Username: " + this.userItem.username;
          this.messPassword = "Password: " + data.new_password;
          this.messageDialog = true;
        })
        .catch((err) => {
          let message = "User's information cannot be updated";
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          }
          this.apiMessage = message;
          this.apiTitle = "Error";
          this.handleMessageProfile();
          this.messageDialog = true;
        });
    },
    closeMessage() {
      this.messageDialog = false;
      this.handleMessageProfile();
    },
    handleMessageProfile() {
      this.messUsername = null;
      this.messPassword = null;
    },
    clearNewUserInfo(){
      this.dataCreateUser = null;
    },
    handleInputCurrentUsername(){
      if (this.timeoutCheckUsername) {
        clearTimeout(this.timeoutCheckUsername);
      }
      this.timeoutCheckUsername = setTimeout(() => {
        this.checkValidCurrentUsername();
      }, 500);
    },
    checkValidCurrentUsername(){
      let userExist = this.users.filter(item => {
        return (this.userName == item.userName);
      });
      if(userExist.length > 0){
        this.userNameTaken = true;
      }else{
        this.userNameTaken = false;
      }
    }
  },
  computed: {
    confirmPassRules() {
      return [this.confirmPass === this.password || "Passwords do not match"];
    },
    newConfirmPassRules() {
      return [
        this.newPassword === this.confirmNewPass || "Passwords do not match",
      ];
    },
    userNameRules(){
      return [
        (v) => !!v || "Username is required",
        (v) => /^[a-zA-Z0-9]{1,10}$/.test(v) || "The username should be 1-10 characters long, consisting of letters and numbers only, with no special characters.",
        !this.userNameTaken || "Username already in use. Please choose a different one.",
      ];
    }
  },
};
</script>
